import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Home from './startup-analyzer';
import Pricing from './Pricing';
import StatX from './dashboard'
import GTM from './gtm'
import Marketing from './marketing'
import ProductSearch from './components/ProductSearch';
import './index.css';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const logPageView = () => {
  window.gtag('config', 'G-RCRCZVCS1V', {
      page_path: window.location.pathname,
  });
};

logPageView();


root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/pricing" element={<Pricing />} />
        <Route path='/StatX' element={<StatX />} />
        <Route path='/GTM' element={<GTM />} />
        <Route path='/marketing' element={<Marketing />} />
        <Route path='/product-search' element={<ProductSearch />} /> */}
      </Routes>
    </Router>
  </React.StrictMode>
);


reportWebVitals();